import React, { useContext, useReducer, useState, useEffect } from 'react'
import { actionType, CallContext, useCallCampaignDispatch } from './CallContext'
import {
  Box,
  TextField,
  Stack,
  TextArea,
  Dropdown,
  PrimaryButton,
  Text,
  Icon,
} from '@leadrilla/pulsar'
import backend from '../../backend'
import { DateTime } from 'luxon'
import { OK } from '../../constants/error_codes'
import { pick } from 'lodash'
import styled from '@emotion/styled'
import { useNotification } from '../../hooks/notification'
import { analyticsEvent } from '../../helpers/heap'
import { useLeadStatuses } from 'src/hooks/queries/leadStatuses'

const CallBox: React.FC = () => {
  const callCampaignContext = useContext(CallContext)
  const dispatch = useCallCampaignDispatch()
  const [outcomeOptions, setOutcomeOptions] = useState<{ text: string; value: string }[]>([])

  const { data: leadStatuses } = useLeadStatuses({
    productId: callCampaignContext?.call?.product_id,
    productType: 'calls', // This is a fallback for when callCampaignContext?.call?.product_id is undefined (it often is)
  })

  useEffect(() => {
    if (leadStatuses) {
      setOutcomeOptions(
        Object.keys(leadStatuses).map((key) => ({ text: leadStatuses[key].name, value: key }))
      )
    }
  }, [leadStatuses])

  const [notes, setNotes] = useState<string>('')
  const updateNotes = (notes: string) => {
    if (notes) {
      sessionStorage.setItem('active-call-notes', notes)
    } else {
      sessionStorage.removeItem('active-call-notes')
    }
    setNotes(notes)
  }

  const [outcome, setOutcome] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [birthdateMessage, setBirthdateMessage] = useState('')

  const processOutcome = (outcome: any) => {
    setOutcome(outcome)
    analyticsEvent('Presence - Outcome changed', { outcome: outcome.text })
  }
  const emptyState = {
    id:
      callCampaignContext.call?.type === 'product' && callCampaignContext?.lead
        ? callCampaignContext.lead.id
        : null,
    first_name: '',
    last_name: '',
    birthdate: '',
    email: '',
    street_address: '',
    city: '',
    state: '',
    zip: '',
  }

  const reducer = (state: any, action: any) => {
    let newState
    if (action.reset) {
      newState = emptyState
      sessionStorage.removeItem('active-call-pii')
    } else {
      newState = {
        ...state,
        ...action,
      }
      sessionStorage.setItem('active-call-pii', JSON.stringify(newState))
    }
    analyticsEvent('Presence - Lead information filled', {
      active_call: callCampaignContext?.call?.active,
    })
    return newState
  }

  const [lead, patchLead] = useReducer(reducer, emptyState)

  const sendNotification = useNotification()

  let disableSave = !outcome

  const submit = async () => {
    if (submitting) return
    if (disableSave) return

    setSubmitting(true)

    const { status } = await backend.put(`/leads/${lead.id}`, {
      ...lead,
      birthdate: lead.birthdate ? DateTime.fromFormat(lead.birthdate, 'yyyy-MM-dd').toSQL() : null,
      notes,
      outcome,
      resume_campaign_id: callCampaignContext?.campaign?.id,
    })
    // @ts-expect-error FIXME
    analyticsEvent('Clicked Save Call Notes')
    if (status === OK) {
      analyticsEvent('Presence - Lead saved successfully', { lead_id: lead.id })
      patchLead({ reset: true })
      if (dispatch)
        dispatch({
          type: actionType.SUBMISSION,
          payload: { enabled: true },
        })
    } else {
      // @ts-expect-error FIXME
      sendNotification({
        type: 'error',
        message: 'Something went wrong. Please refresh and try again.',
      })
    }

    setSubmitting(false)
  }

  const [ranSetup, setRanSetup] = useState(false)

  useEffect(() => {
    if (callCampaignContext?.call?.id && callCampaignContext?.lead?.id && !ranSetup) {
      setRanSetup(true)
      const unparsedSavedState = sessionStorage.getItem('active-call-pii')
      let savedState = unparsedSavedState ? JSON.parse(unparsedSavedState) : {}
      const savedNotes = sessionStorage.getItem('active-call-notes')

      if (savedState.id !== callCampaignContext.lead.id) {
        savedState = {}
        sessionStorage.removeItem('active-call-pii')
        sessionStorage.removeItem('active-call-notes')
      } else if (savedNotes) {
        setNotes(savedNotes)
      }

      patchLead({
        ...pick(callCampaignContext.lead, [
          'id',
          'first_name',
          'last_name',
          'email',
          'street_address',
          'city',
          'state',
          'zip',
        ]),
        birthdate: callCampaignContext.lead.birthdate
          ? DateTime.fromISO(callCampaignContext?.lead?.birthdate).toFormat('yyyy-MM-dd')
          : '',
        ...savedState,
      })
    }
  }, [callCampaignContext, ranSetup])

  return (
    <StyledBox width="100%">
      {callCampaignContext?.call && (
        <Box display={['block', 'flex']} justifyContent="between" marginBottom={['xxl', 'none']}>
          {!callCampaignContext?.lead?.hidden ? (
            <>
              <StyledBox width={['100%', '50%']} inset="m">
                <Stack space="m">
                  <div className="flex justify-between space-x-8">
                    <TextField
                      value={lead.first_name}
                      onChange={(e) => patchLead({ first_name: e.target.value })}
                      label="First Name"
                      id="presence-lead-information-field"
                    />
                    <TextField
                      value={lead.last_name}
                      onChange={(e) => patchLead({ last_name: e.target.value })}
                      label="Last Name"
                      id="presence-lead-information-field"
                    />
                  </div>
                  <TextField
                    value={lead.email}
                    onChange={(e) => patchLead({ email: e.target.value })}
                    label="Email"
                    type="email"
                    id="presence-lead-information-field"
                  />
                  <TextField
                    value={lead.street_address}
                    onChange={(e) => patchLead({ street_address: e.target.value })}
                    label="Address"
                    id="presence-lead-information-field"
                  />
                  <TextField
                    value={lead.city}
                    onChange={(e) => patchLead({ city: e.target.value })}
                    label="City"
                    id="presence-lead-information-field"
                  />
                  <Box display="flex" justifyContent="between">
                    <Box marginRight="s">
                      <TextField
                        value={lead.state}
                        onChange={(e) => patchLead({ state: e.target.value })}
                        label="State"
                        id="presence-lead-information-field"
                      />
                    </Box>
                    <Box marginLeft="s">
                      <TextField
                        value={lead.zip}
                        onChange={(e) => patchLead({ zip: e.target.value })}
                        label="Zip code"
                        id="presence-lead-information-field"
                      />
                    </Box>
                  </Box>
                </Stack>
              </StyledBox>
              <Box width={['100%', '50%']} inset="m">
                <Stack space="m">
                  <TextField
                    type="date"
                    value={lead.birthdate}
                    onChange={(e) => {
                      const regex = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/gm
                      if (!regex.test(e.target.value)) {
                        setBirthdateMessage('Invalid format')
                      } else {
                        setBirthdateMessage('')
                      }
                      patchLead({ birthdate: e.target.value })
                    }}
                    label="Date of birth"
                    placeholder="mm/dd/yyyy"
                    message={birthdateMessage}
                    id="presence-lead-information-field"
                  />

                  <StyledTextArea
                    name="notes"
                    label="Notes"
                    value={notes}
                    onChange={(e) => updateNotes(e.target.value)}
                    rows={10}
                  />
                  {outcomeOptions.length > 0 && (
                    <Dropdown
                      label="Outcome*"
                      options={outcomeOptions}
                      value={outcome}
                      onChange={(v) => processOutcome(v)}
                      optionsMaxHeight="120px"
                      native
                    />
                  )}
                  <PrimaryButton
                    fullWidth
                    onClick={submit}
                    disabled={disableSave}
                    id="presence-save-call-notes-button"
                  >
                    Save call notes
                  </PrimaryButton>
                  <Text tone="light" size="small">
                    *Required
                  </Text>
                </Stack>
              </Box>
            </>
          ) : (
            <Box width={['100%']} inset="m">
              <Stack space="m">
                <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                  <CenteredIcon name="phone" tone="positive" size="xxl" />
                  <StyledText size="huge" weight="stronger" tone="light" align="center">
                    Call connected
                  </StyledText>
                </Box>
              </Stack>
            </Box>
          )}
        </Box>
      )}
    </StyledBox>
  )
}
const StyledTextArea = styled(TextArea)`
  height: 160px;
  resize: none;
`
const StyledBox = styled(Box)`
  overflow-y: auto;
  max-height: calc(100vh);
`

const CenteredIcon = styled(Icon)`
  position: absolute;
  transform: translateX(12px) translateY(0);
  size: 80px;
  height: 100px !important;
  width: 100px !important;
  z-index: 10;
  color: var(--colors-hairline) !important;
  opacity: 0.4;
`
const StyledText = styled(Text)`
  z-index: 11;
`
export default CallBox
